import React from "react"
import {
  IoIosCheckmarkCircleOutline,
  IoIosInformationCircleOutline,
  IoIosMailOpen,
  IoIosTime,
} from "react-icons/io"
import { IoPencilOutline } from "react-icons/io5"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { useTranslation } from "react-i18next"

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" }
  return new Date(dateString).toLocaleDateString(undefined, options)
}

const iconForStatus = (status) => {
  const iconStyle = { fontSize: "1.5em" }
  switch (status) {
    case "Awaiting Information":
      return <IoIosInformationCircleOutline style={iconStyle} />
    case "To Write":
      return <IoPencilOutline style={iconStyle} />
    case "Published":
      return <IoIosCheckmarkCircleOutline style={iconStyle} />
    case "Sent":
      return <IoIosMailOpen style={iconStyle} />
    case "Awaiting Approval":
      return <IoIosTime style={iconStyle} />
    default:
      return null
  }
}

const OrdersTable = ({ orders }) => {
  const { t } = useTranslation()

  return (
    <div className="overflow-x-auto relative shadow-md sm:rounded-lg pb-4">
      <Table className="table">
        <Thead>
          <Tr>
            <Th>{t("table_header_website")}</Th>
            <Th>{t("table_header_publication_url")}</Th>
            <Th>{t("table_header_price")}</Th>
            <Th>{t("table_header_status")}</Th>
            <Th>{t("table_header_order_date")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {orders.map((order) => (
            <Tr key={order.item_id}>
              <Td>
                <a
                  href={`https://${order.itemSite.website_name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {order.itemSite.website_name}
                </a>
              </Td>
              <Td>
                {order.publication_url ? (
                  <a
                    href={order.publication_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Link
                  </a>
                ) : (
                  "N/A"
                )}
              </Td>
              <Td>{`${order.sale_price} ${order.currency_code}`}</Td>
              <Td className="flex items-center gap-2">
                {order.itemStatus.status_name}
                {iconForStatus(order.itemStatus.status_name)}
              </Td>
              <Td>{formatDate(order.order_date)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  )
}

export default OrdersTable
