import React from "react"
import PackCard from "../productsPage/PackCard"

const PackContainer = ({ packs, text }) => {
  const isLoading = !packs || packs.length === 0

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    )
  }

  return (
    <div className="bg-white">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl pt-5">
        {text}
      </h2>
      {packs.map((pack) => (
        <PackCard key={pack.id} pack={pack} />
      ))}
    </div>
  )
}

export default PackContainer
