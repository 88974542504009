import React from "react"
import { useTranslation } from "react-i18next"

const AboutUs = () => {
  const { t } = useTranslation()

  // Structured data
  const organizationData = {
    "@context": "http://schema.org",
    "@type": "Organization",
    name: "SEOfy",
    url: "https://seofy.link/",
    logo: "/logo.jpg",
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: "+13055905383",
        contactType: "Customer Service",
      },
    ],
    address: {
      "@type": "PostalAddress",
      streetAddress: "3401 SW 160th Ave. Suite 330",
      addressLocality: "Miramar",
      addressRegion: "FL",
      postalCode: "33027",
      addressCountry: "USA",
    },
    sameAs: [
      "https://www.instagram.com/seofy_communication/?igshid=OGQ5ZDc2ODk2ZA%3D%3D",
      "https://www.linkedin.com/company/seofy-agency",
    ],
  }

  return (
    <div className="w-full">
      <h2 className="text-2xl font-bold mb-4">{t("about_us_title")}</h2>
      <img
        src="/OFICINA.jpg"
        alt={t("about_us_image_alt")}
        className="rounded-lg mb-4"
      />
      <p className="mb-2">{t("about_us_address")}</p>
      <script type="application/ld+json">
        {JSON.stringify(organizationData)}
      </script>
    </div>
  )
}

export default AboutUs
