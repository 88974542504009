import axios from "axios"


const API_URL = process.env.REACT_APP_API_URL

const checkAndCreateAffiliate = async (name, email) => {
  try {
    // Prepare the data to be sent in the request
    const affiliateData = { name, email }

    // Send a POST request to check and create the affiliate
    const response = await axios.post(`${API_URL}/add-affiliate`, affiliateData)

    // Return the response data
    return response.data
  } catch (error) {
    throw error
  }
}

export { checkAndCreateAffiliate }
