import React from "react"
import { useTranslation } from "react-i18next"

const Stats = () => {

  const { t } = useTranslation()

  return (
    <div className="md:stats shadow">
      <div className="stat">
        <div className="stat-title">{t("stats_sites_title")}</div>
        <div className="stat-value text-primary">{t("stats_sites_number")}</div>
        <div className="stat-title">{t("stats_sites_description")}</div>
      </div>

      <div className="stat">
        <div className="stat-title">{t("stats_posts_title")}</div>
        <div className="stat-value text-primary">{t("stats_posts_number")}</div>
        <div className="stat-title">{t("stats_posts_description")}</div>
      </div>

      <div className="stat">
        <div className="stat-title">{t("stats_reviews_title")}</div>
        <div className="stat-value text-primary">{t("stats_reviews_number")}</div>
        <div className="flex justify-center mt-2 px-3">
          <img src="FIVERR.png" alt="Logo Fiverr" className="h-6 mx-3" />
          <img src="GOOGLE.webp" alt="Logo Google" className="h-6 mx-3" />
          <img src="UPWORK.svg.png" alt="Logo Upwork" className="h-6 mx-3" />
        </div>
      </div>
    </div>
  )
}

export default Stats
