import React from "react"
import PackContainer from "../homepage/PacksContainer"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

function ProductsPage() {
  const { t } = useTranslation()
  const packs = useSelector((state) => state.packs.list)

  return (
    <div>
      <PackContainer packs={packs} text={t("all_packs_text")} />
    </div>
  )
}

export default ProductsPage
