import axios from "axios"
import Cookies from "js-cookie"

const API_URL = process.env.REACT_APP_API_URL

const getPublicSites = async () => {
  const token = Cookies.get("token")
  try {
    const response = await axios.get(`${API_URL}/sites`, {
      headers: {
        Authorization: `Bearer ${token}`, 
      },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const postSiteInterest = async (siteInfo) => {
  try {
    const response = await axios.post(`${API_URL}/site`, siteInfo)
    return response.data
  } catch (error) {
    throw error
  }
}

export { getPublicSites, postSiteInterest }
