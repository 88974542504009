import { createSlice } from "@reduxjs/toolkit"

export const packSlice = createSlice({
  name: "packs",
  initialState: {
    list: [],
  },
  reducers: {
    setPacks: (state, action) => {
      state.list = action.payload
    },
  },
})

export const { setPacks } = packSlice.actions

export default packSlice.reducer
