import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL

const signup = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}/signup`, credentials)
    return response.data
  } catch (error) {
    throw error
  }
}

const checkAffiliate = async (name) => {
  try {
    const response = await axios.post(`${API_URL}/check-affiliate`, { name })
    return response.data
  } catch (error) {
    throw error
  }
}

export { signup, checkAffiliate}
