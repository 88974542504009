import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { signup, checkAffiliate } from "../../services/signUpService"
import Cookies from "js-cookie"
import { useTranslation } from "react-i18next"

export default function AffiliateSignUpPage() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { affiliateName } = useParams()
  const currentLanguage = useSelector((state) => state.language.lang)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    clientType: "",
    affiliateId: null,
  })

  useEffect(() => {
    const token = Cookies.get("token")
    if (token) {
      navigate("/sites")
    }

    const verifyAffiliate = async () => {
      if (affiliateName) {
        try {
        const response = await checkAffiliate(affiliateName.replace(/-/g, " ")) 
        setCredentials((prevCredentials) => ({
          ...prevCredentials,
          affiliateId: response.affiliateId, 
        }))
        } catch (error) {
          navigate("/signup") 
        }
      }
    }

    verifyAffiliate()
  }, [navigate, affiliateName])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (credentials.password !== credentials.confirmPassword) {
      setError("Passwords do not match.")
      return
    }

    setLoading(true) // Iniciar el indicador de carga
    try {
      const responseData = await signup(credentials)
      console.log(responseData)
      handleNavigate()
    } catch (error) {
      setError(error.response?.data || "An error occurred during signup.")
    } finally {
      setLoading(false) // Detener el indicador de carga
    }
  }
  const handleNavigate = () => {
    let destinationURL

    if (currentLanguage === "en") {
      destinationURL = `/login`
    } else {
      destinationURL = `/${currentLanguage}/login`
    }
    navigate(destinationURL)
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <div>
          <h2 className="mt-2 text-center text-2xl font-semibold">
            SEOfy |<span className="text-lg"> Invited By {affiliateName}</span>
          </h2>
        </div>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="email" className="block text-sm font-medium">
                Email
              </label>
            </div>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                onChange={handleInputChange}
                placeholder="Your email"
                className="input input-bordered w-full rounded-md border-0 py-1.5 text-gray-900 bg-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium">
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                placeholder="Your password"
                onChange={handleInputChange}
                className="input input-bordered w-full rounded-md border-0 py-1.5 text-gray-900 bg-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium"
              >
                Confirm Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                autoComplete="new-password"
                required
                placeholder="Confirm password"
                onChange={handleInputChange}
                className="input input-bordered w-full rounded-md border-0 py-1.5 text-gray-900 bg-white shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 placeholder:text-gray-400"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium">
              <div className="label">
                <span className="label-text">{t("signup_select")}</span>
              </div>
              <select
                name="clientType"
                onChange={handleInputChange}
                className="select select-bordered bg-white w-full"
                defaultValue=""
                required
              >
                <option disabled value="">
                  {t("signup_option")}
                </option>
                <option value="agency">{t("signup_seo_option")}</option>
                <option value="endClient">{t("signup_client_option")}</option>
                <option value="freelancer">
                  {t("signup_freelancer_option")}
                </option>
                <option value="other">{t("signup_other_option")}</option>
              </select>
            </label>
          </div>

          {error && (
            <div role="alert" className="alert alert-error">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span>
                {t("login_error")} {error}
              </span>
            </div>
          )}

          <div>
            <button
              type="submit"
              className="btn btn-primary w-full"
              disabled={loading}
            >
              {loading ? (
                <span className="loading loading-spinner loading-md"></span>
              ) : (
                t("signup_button")
              )}
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm ">
          {t("signup_already_have_account")}
          <button
            onClick={handleNavigate}
            className="font-semibold leading-6 text-accent ml-1"
          >
            {t("signup_login_text")}
          </button>
        </p>
      </div>
    </div>
  )
}
