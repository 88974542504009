import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { requestPasswordReset } from "../../services/loginService" 

export default function PasswordResetPage() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("") 

  const handleInputChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError("")
    setMessage("")
    try {
      await requestPasswordReset(email) 
      setMessage(
        "An email with password reset instructions has been sent to your address. Please check your spam folder if you do not see it in your inbox."
      ) 
    } catch (error) {
      const errorMessage =
        error.response?.data.error || t("reset_error_message")
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  const handleNavigateToLogin = () => {
    navigate("/login")
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div>
          <img
            className="mx-auto h-20 w-auto"
            src="/SEOfy.svg"
            alt="Company logo"
          />
        </div>

        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-accent">
          {t("reset_password_text")}
        </h2>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="email" className="block text-sm font-medium">
                  Email
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder={t("your_email")}
                  required
                  value={email}
                  onChange={handleInputChange}
                  className="input input-bordered w-full rounded-md border-0 py-1.5 text-gray-900 bg-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            {error && (
              <div role="alert" className="alert alert-error">
                <span>{error}</span>
              </div>
            )}

            {message && (
              <div role="alert" className="alert alert-success">
                <span>{message}</span>
              </div>
            )}

            <div>
              <button
                type="submit"
                className="btn btn-primary w-full"
                disabled={loading}
              >
                {loading ? (
                  <span className="loading loading-spinner loading-md"></span>
                ) : (
                  t("reset_button")
                )}
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm ">
            <button
              onClick={handleNavigateToLogin}
              className="font-semibold leading-6 text-accent ml-1"
            >
              {t("login_text")}
            </button>
          </p>
        </div>
      </div>
    </div>
  )
}
