import React from "react"
import { MdOutlineArrowDropDownCircle } from "react-icons/md"

function SitesHeader({ languages, selectedLanguage, setSelectedLanguage }) {
  // Idiomas fijos para mostrar siempre
  const fixedLanguages = [
    "Spanish",
    "Portuguese",
    "English",
    "Italian",
    "French",
    "German",
  ]



  // Filtrar los idiomas fijos de la lista de la base de datos
  const otherLanguages = languages.filter(
    (language) => !fixedLanguages.includes(language)
  )

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language)
  }

  return (
    <div>
      <div
        role="tablist"
        className="tabs tabs-bordered flex items-center justify-around"
      >
        <button
          role="tab"
          className={`tab ${
            selectedLanguage === "Best Sellers" ? "tab-active " : ""
          }`}
          onClick={() => handleLanguageChange("Best Sellers")}
        >
          Top Choices
        </button>
        <button
          role="tab"
          className={`tab ${
            selectedLanguage === "All" ? "tab-active " : ""
          }`}
          onClick={() => handleLanguageChange("All")}
        >
          All
        </button>
        {fixedLanguages.map((language, index) => (
          <button
            role="tab"
            className={`tab ${
              selectedLanguage === language ? "tab-active" : ""
            }`}
            key={index}
            onClick={() => handleLanguageChange(language)}
          >
            {language}
          </button>
        ))}
        {otherLanguages.length > 0 && (
          <div className="flex items-center">
            <details className="dropdown dropdown-bottom dropdown-end">
              <summary className="m-1 btn">
                More Languages <MdOutlineArrowDropDownCircle />
              </summary>
              <ul className="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box w-52">
                {otherLanguages.map((language, index) => (
                  <li key={index}>
                    <button onClick={() => handleLanguageChange(language)}>
                      {language}
                    </button>
                  </li>
                ))}
              </ul>
            </details>
          </div>
        )}
      </div>
    </div>
  )
}

export default SitesHeader
