import React from "react"

function SitesHeaderMobile({
  languages,
  selectedLanguage,
  setSelectedLanguage,
}) {
  const fixedLanguages = [
    "Spanish",
    "Portuguese",
    "English",
    "Italian",
    "French",
    "German",
  ]

  const allLanguages = [
    "Best Sellers",
    "All",
    ...fixedLanguages,
    ...languages.filter((language) => !fixedLanguages.includes(language)),
  ]

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value)
  }

  return (
    <div className="overflow-x-auto p-9">
      <div className="flex justify-between items-center">
        <select
          className="select select-bordered w-full max-w-xs"
          value={selectedLanguage}
          onChange={handleLanguageChange}
        >
          {allLanguages.map((language, index) => (
            <option key={index} value={language}>
              {language}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default SitesHeaderMobile
