import { FaCheck } from "react-icons/fa6"
import { CiClock2 } from "react-icons/ci"
import { format } from "date-fns"
import { IoIosStats } from "react-icons/io"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

const PackCard = ({ pack }) => {
  // Structured data
  const productData = {
    "@context": "http://schema.org",
    "@type": "Product",
    name: pack.title,
    description: pack.description,
    offers: {
      "@type": "Offer",
      price: pack.price,
      priceCurrency: "USD",
    },
  }

  const { t } = useTranslation()
  const currentLanguage = useSelector((state) => state.language.lang)
  const navigate = useNavigate()

  const handleButtonClick = () => {
    let destinationURL

    if (currentLanguage === "en") {
      destinationURL = `/buy`
    } else {
      destinationURL = `/${currentLanguage}/buy`
    }

    navigate(destinationURL, { state: { productTitle: pack.title } })
  }

  const formattedDeadline = pack.deadline
    ? format(new Date(pack.deadline), "PP")
    : ""
  const deadlineDate = new Date(formattedDeadline)
  const currentDate = new Date()

  // Calcular la diferencia en meses
  const differenceInMonths =
    deadlineDate.getMonth() -
    currentDate.getMonth() +
    12 * (deadlineDate.getFullYear() - currentDate.getFullYear())

  return (
    <div className="bg-white p-5">
      <div className="max-w-2xl rounded-3xl ring-1  ring-gray-200  lg:flex lg:max-w-none">
        <div className="p-8 sm:p-10 lg:flex-auto">
          <h3 className="text-2xl font-bold tracking-tight text-gray-900">
            {pack.title}
          </h3>
          <p className="mt-6 text-base leading-7 text-gray-600">
            {pack.description}
          </p>
          <div className="mt-10 flex items-center gap-x-4">
            <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
              {t("pack_card_included")}
            </h4>
            <div className="h-px flex-auto bg-gray-100" />
          </div>
          <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
            {pack.dr_stat && (
              <li className="flex gap-x-3">
                <IoIosStats
                  className="h-6 w-5 flex-none text-indigo-600"
                  aria-hidden="true"
                />
                {pack.dr_stat}
              </li>
            )}
            {pack.tat_stat && (
              <li className="flex gap-x-3">
                <CiClock2
                  className="h-6 w-5 flex-none text-indigo-600"
                  aria-hidden="true"
                />
                {pack.tat_stat}
              </li>
            )}
            {pack.writing_included && (
              <li className="flex gap-x-3">
                <FaCheck
                  className="h-6 w-5 flex-none text-indigo-600"
                  aria-hidden="true"
                />
                {t("pack_card_writing")}
              </li>
            )}
            {pack.link_stat && (
              <li className="flex gap-x-3">
                <FaCheck
                  className="h-6 w-5 flex-none text-indigo-600"
                  aria-hidden="true"
                />
                {pack.link_stat}
              </li>
            )}
          </ul>
        </div>
        <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
          <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
            <div className="mx-auto max-w-xs px-8">
              <p className="text-base font-semibold text-gray-600">
                {t("pack_card_pay")}
              </p>
              <p className="mt-6 flex items-baseline justify-center gap-x-2">
                <span className="text-5xl font-bold tracking-tight text-gray-900">
                  ${pack.price}
                </span>
                <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                  USD
                </span>
              </p>
              <button
                onClick={handleButtonClick}
                className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {t("pack_card_start")}
              </button>
              <p className="mt-6 text-xs leading-5 text-gray-600">
                {pack.extra_details}
              </p>
              <div>
                {differenceInMonths < 3 && (
                  <h3 className="mt-2 text-xl font-bold tracking-tight text-gray-900">
                    {t("pack_card_available")} {formattedDeadline}
                  </h3>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <script type="application/ld+json">{JSON.stringify(productData)}</script>
    </div>
  )
}

export default PackCard
