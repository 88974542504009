import React from "react"
import { FaInstagram } from "react-icons/fa"
import { SiFiverr } from "react-icons/si"
import { FaLinkedin } from "react-icons/fa"
import { FaWhatsapp } from "react-icons/fa"
import { useTranslation } from "react-i18next"
import ReactGA from "react-ga4"
import { useLocation } from "react-router-dom"
import { useEffect } from "react"

const Footer = () => {
  const location = useLocation()
  
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname })
  }, [location])

  const { t } = useTranslation()
  ReactGA.send({ hitType: "pageview", page: location.pathname })

  return (
    <footer className="footer p-10 bg-base-300 text-base-content">
      <div>
        <header className="footer-title">{t("footer_usa_office_title")}</header>
        <p>Ivana Nazareno</p>
        <p>
          <a href="mailto:contact@seofy.link">contact@seofy.link</a>
        </p>
        <p>
          <a
            href="https://wa.me/13055905383"
            className="inline-flex items-center"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp className="mr-2" />
            +1 305 590 5383
          </a>
        </p>
      </div>

      <div>
        <header className="footer-title">
          {t("footer_latam_office_title")}
        </header>
        <p>Ezequiel Rapoport</p>
        <p>
          <a href="mailto:contact@seofy.link">contact@seofy.link</a>
        </p>
        <p>
          <a
            href={`https://wa.me/5493412753928`}
            className="inline-flex items-center"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp className="mr-2" />
            +54 9 3412753928
          </a>
        </p>
      </div>

      <div>
        <header className="footer-title">
          {t("footer_china_office_title")}
        </header>
        <p>Li Yuqin</p>
        <a href="mailto:alyssa.li@seofy.com">alyssa.li@seofy.link</a>
        <p className="flex items-center">
          <a
            href="https://wa.me/8618577339825"
            className="inline-flex items-center"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp className="mr-2" />
            +86 185 7733 9825
          </a>
        </p>
        {/*   <p className="flex items-center">
          <a href={`weixin://dl/chat?${wechatID}`} className="mr-2">
            <BsWechat />
          </a>
          {wechatID}
        </p> */}
      </div>

      <div>
        <header className="footer-title">{t("footer_social_title")}</header>
        <div className="grid grid-flow-col gap-4">
          <a
            href="https://instagram.com/seofy_communication?igshid=OGQ5ZDc2ODk2ZA=="
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="text-2xl" />
          </a>
          <a
            href="https://www.fiverr.com/iviseo?source=gig_page"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SiFiverr className="text-2xl" />
          </a>
          <a
            href="https://www.linkedin.com/company/seofy-agency"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="text-2xl" />
          </a>
        </div>
        <p className="text-xs">IN-Communication LLC</p>
      </div>
    </footer>
  )
}

export default Footer
