import React, { useState } from "react"
import { checkAndCreateAffiliate } from "../../services/affiliateService"
import WelcomeAffiliate from "./WelcomeAffiliate"

const AffiliateProgram = () => {
  const [affiliateName, setAffiliateName] = useState("")
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [isRegistered, setIsRegistered] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()

    setError("")

    try {
      const response = await checkAndCreateAffiliate(affiliateName, email)
      console.log("Response:", response)
      setIsRegistered(true)
    } catch (error) {
      setError(error.response?.data || "An unexpected error occurred")
      console.error("Error:", error)
    }
  }

  const affiliateUrl = `seofy.link/signup/${encodeURIComponent(
    affiliateName
  )}`

  if (isRegistered) {
    return <WelcomeAffiliate affiliateUrl={affiliateUrl} />
  }

  return (
    <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8 flex items-start">
      <div className="flex flex-wrap lg:flex-nowrap w-full">
        <div className="w-full lg:w-1/2 text-center px-6">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Welcome to Our Affiliate Program!
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            We're excited to offer you the opportunity to join our affiliate
            program. By becoming an affiliate, you can earn extra income simply
            by promoting our products.
          </p>
          <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mt-3">
            How It Works
          </h3>
          <ul className="list-none ml-5 text-gray-600 mt-3">
            <li>
              <b>Sign Up:</b> Register on our website to become an affiliate.
              It's quick and easy!
            </li>
            <li>
              <b>Get Your Link:</b> Once you're registered, you’ll receive a
              personalized link. Share it anywhere!
            </li>
            <li>
              <b>Start Earning:</b> Earn $5 for every order placed through your
              link.
            </li>
          </ul>
          <div className="mt-6">
            <h3 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl mt-3">
              Share and Earn
            </h3>
            <ul className="list-none ml-5 text-gray-600 mt-3">
              <li>
                <b>Unlimited Earning Potential:</b> The more you share, the more
                you earn.
              </li>
              <li>
                <b>Track Your Success:</b> Access your dashboard to track
                earnings and orders.
              </li>
            </ul>
            <p className="text-xs text-gray-500 mt-4">
              *Important: This affiliate program is only applicable to new
              customers. Existing customers referred by affiliates are not
              eligible for rewards
            </p>
          </div>
        </div>

        <div className="w-full lg:w-1/2 flex items-center justify-center">
          <form onSubmit={handleSubmit} className="w-full max-w-4xl px-4 py-6">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Get Started
            </h2>
            <div className="mt-6">
              <label
                htmlFor="company"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Affiliate Name
              </label>
              <input
                type="text"
                name="company"
                id="company"
                autoComplete="organization"
                required="true"
                className="mt-2.5 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-white"
                value={affiliateName}
                onChange={(e) => setAffiliateName(e.target.value)}
              />
              <span className="text-xs text-gray-600 block mt-1">
                (Name displayed to customers. It can be your company,
                organization, or personal brand)
              </span>
            </div>
            <div className="mt-6">
              <label
                htmlFor="email"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                required="true"
                className="mt-2.5 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-white"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="text-xs text-gray-600 block mt-1">
                (Email must be from an existing account)
              </span>
              {error && (
                <div className="text-red-500 text-sm mt-2">{error}</div>
              )}
            </div>
            <div className="mt-10">
              <button
                type="submit"
                className="w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Start Earning
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AffiliateProgram
