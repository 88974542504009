import { Fragment, useState } from "react"
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react"
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline"
import { ChevronDownIcon } from "@heroicons/react/20/solid"
import { useNavigate } from "react-router-dom"
import i18next from "i18next"
import { useSelector, useDispatch } from "react-redux"
import { setLanguage } from "../../reducers/languageReducer"
import { useTranslation } from "react-i18next"
import Cookies from "js-cookie"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const dispatch = useDispatch()
  const currentLanguage = useSelector((state) => state.language.lang)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const userInfoEncoded = Cookies.get("userInfo")
  const isLoggedIn = !!userInfoEncoded

  const getFlagImage = (lang) => {
    const flags = {
      en: "/USA.png",
      es: "/SPAIN.png",
      pt: "/PORTUGAL.png",
    }
    return flags[lang]
  }

  const handleNavigate = (route) => {
    let destinationURL

    if (currentLanguage === "en") {
      destinationURL = `/${route}`
    } else {
      destinationURL = `/${currentLanguage}/${route}`
    }
    setMobileMenuOpen(false)
    navigate(destinationURL)
  }

  const handleLogout = () => {
    Cookies.remove("token")
    Cookies.remove("userInfo")

    handleNavigate("") 
  }

  const handleChangeLanguage = (lang) => {
    dispatch(setLanguage(lang))
    i18next.changeLanguage(lang.toLowerCase())

    const currentPath = window.location.pathname
    // Eliminar cualquier prefijo de idioma existente
    const pathWithoutLang = currentPath.replace(/^\/(es|pt)/, "")

    let newPath = pathWithoutLang
    if (lang.toLowerCase() === "es") {
      newPath = pathWithoutLang === "/" ? "/es" : `/es${pathWithoutLang}`
    } else if (lang.toLowerCase() === "pt") {
      newPath = pathWithoutLang === "/" ? "/pt" : `/pt${pathWithoutLang}`
    }

    navigate(newPath || "/")
    setMobileMenuOpen(false)
  }

  return (
    <header>
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <button
            onClick={() => handleNavigate("")}
            className="btn btn-ghost text-xl"
          >
            <img
              src="/LOGO.png"
              alt="Logo"
              className="h-auto w-auto max-h-10"
            />
            <p className="text-xs md:text-lg text-white">Link Building & SEO</p>
          </button>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <button
            className="text-sm font-semibold leading-6 text-white"
            onClick={() => handleNavigate("sites")}
          >
            {t("sites_route")}
          </button>

          <button
            className="text-sm font-semibold leading-6 text-white"
            onClick={() => handleNavigate("products")}
          >
            {t("products_route")}
          </button>

          <button
            className="text-sm font-semibold leading-6 text-white"
            onClick={() => handleNavigate("orders")}
          >
            {t("orders_route")}
          </button>

          <button
            className="text-sm font-semibold leading-6 text-white"
            onClick={() => handleNavigate("contact")}
          >
            {t("contact_route")}
          </button>

          <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-white">
              <img
                src={getFlagImage(currentLanguage)}
                alt={`${currentLanguage} flag`}
                className="mr-2 w-5 h-auto"
              />
              {currentLanguage.toUpperCase()}
              <ChevronDownIcon
                className="h-5 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  <button
                    className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                    onClick={() => handleChangeLanguage("en")}
                  >
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg   hover:bg-gray-50">
                      <img
                        src="/USA.png"
                        alt="American flag"
                        className="mr-2 w-5 h-auto "
                      />
                    </div>
                    <div className="flex-auto">
                      <p className="block font-semibold text-gray-900">
                        English
                        <span className="absolute inset-0" />
                      </p>
                    </div>
                  </button>

                  <button
                    className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                    onClick={() => handleChangeLanguage("es")}
                  >
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg   hover:bg-gray-50">
                      <img
                        src="/SPAIN.png"
                        alt="Spanish flag"
                        className="mr-2 w-5 h-auto"
                      />
                    </div>
                    <div className="flex-auto">
                      <p className="block font-semibold text-gray-900">
                        Spanish
                        <span className="absolute inset-0" />
                      </p>
                    </div>
                  </button>

                  <button
                    className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                    onClick={() => handleChangeLanguage("pt")}
                  >
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg   hover:bg-gray-50">
                      <img
                        src="/PORTUGAL.png"
                        alt="Portuguese flag"
                        className="mr-2 w-5 h-auto"
                      />
                    </div>
                    <div className="flex-auto">
                      <p className="block font-semibold text-gray-900">
                        Portuguese
                        <span className="absolute inset-0" />
                      </p>
                    </div>
                  </button>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </Popover.Group>

        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {isLoggedIn ? (
            <button
              className="text-sm font-semibold leading-6 text-white"
              onClick={handleLogout}
            >
              Log out <span aria-hidden="true">&larr;</span>
            </button>
          ) : (
            <div className="flex items-center gap-3">
              <button
                className="text-sm font-semibold leading-6 text-white"
                onClick={() => handleNavigate("login")}
              >
                Log in
              </button>
              <button
                className="btn btn-accent"
                onClick={() => handleNavigate("signup")}
              >
                Sign Up
              </button>
            </div>
          )}
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <div className="-m-1.5 p-1.5">
              <img
                src="/LOGO.png"
                alt="Logo"
                className="h-auto w-auto max-h-10"
              />
            </div>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <button
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={() => handleNavigate("sites")}
                >
                  {t("sites_route")}
                </button>

                <button
                  onClick={() => handleNavigate("products")}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {t("products_route")}
                </button>

                <button
                  onClick={() => handleNavigate("orders")}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {t("orders_route")}
                </button>

                <button
                  onClick={() => handleNavigate("contact")}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {t("contact_route")}
                </button>

                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        <div className="flex justify-center items-center">
                          <img
                            src={getFlagImage(currentLanguage)}
                            alt={`${currentLanguage} flag`}
                            className="mr-2 w-5 h-auto"
                          />
                          {currentLanguage.toUpperCase()}
                        </div>

                        <ChevronDownIcon
                          className={classNames(
                            open ? "rotate-180" : "",
                            "h-5 w-5 flex-none"
                          )}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        <Disclosure.Button
                          className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          onClick={() => handleChangeLanguage("en")}
                        >
                          <div className="flex justify-center items-center">
                            <img
                              src="/USA.png"
                              alt="American flag"
                              className="mr-2 w-5 h-auto"
                            />
                            <p>English</p>
                          </div>
                        </Disclosure.Button>

                        <Disclosure.Button
                          className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          onClick={() => handleChangeLanguage("es")}
                        >
                          <div className="flex justify-center items-center">
                            <img
                              src="/SPAIN.png"
                              alt="Spanish flag"
                              className="mr-2 w-5 h-auto"
                            />
                            <p>Spanish</p>
                          </div>
                        </Disclosure.Button>

                        <Disclosure.Button
                          className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          onClick={() => handleChangeLanguage("pt")}
                        >
                          <div className="flex justify-center items-center">
                            <img
                              src="/PORTUGAL.png"
                              alt="Portuguese flag"
                              className="mr-2 w-5 h-auto"
                            />
                            <p>Portuguese</p>
                          </div>
                        </Disclosure.Button>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
              <div className="py-6">
                {isLoggedIn ? (
                  <button
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={handleLogout}
                  >
                    Log out
                  </button>
                ) : (
                  <div className="flex flex-col items-start justify-center">
                    <div className="flex flex-col items-center">
                      <button
                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        onClick={() => handleNavigate("login")}
                      >
                        Log in
                      </button>
                      <button
                        className="btn btn-accent"
                        onClick={() => handleNavigate("signup")}
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}

export default Header
