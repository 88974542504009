import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import Cookies from "js-cookie"
import { login } from "../../services/loginService"
import { useTranslation } from "react-i18next"

export default function LoginPage() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const currentLanguage = useSelector((state) => state.language.lang)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  })

  useEffect(() => {
    const token = Cookies.get("token")
    if (token) {
      navigate("/sites")
    }
  }, [navigate])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      // Llamar al servicio de inicio de sesión con las credenciales
      const response = await login(credentials)
      console.log("Login Successful:", response)

      navigate("/sites")
    } catch (error) {
      const errorMessage =
        error.response?.data.error || "An error occurred during login."
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  const handleNavigate = () => {
    let destinationURL

    if (currentLanguage === "en") {
      destinationURL = `/signup`
    } else {
      destinationURL = `/${currentLanguage}/signup`
    }
    navigate(destinationURL)
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <div>
          <img
            className="mx-auto h-20 w-auto"
            src="/SEOfy.svg"
            alt="Company logo"
          />
        </div>

        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-accent">
          {t("login_text")}
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="email" className="block text-sm font-medium">
                Email
              </label>
            </div>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="Your email"
                required
                onChange={handleInputChange}
                className="input input-bordered w-full rounded-md border-0 py-1.5 text-gray-900 bg-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium">
                Password
              </label>
              <div className="text-sm">
                <button
                  type="button"
                  onClick={() => navigate("/password-reset")}
                  className="font-semibold text-accent"
                >
                  {t("login_forgot_password")}
                </button>
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                placeholder="Your Password"
                onChange={handleInputChange}
                className="input input-bordered w-full rounded-md border-0 py-1.5 text-gray-900 bg-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          {error && (
            <div role="alert" className="alert alert-error">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span>
                {t("login_error")} {error}
              </span>
            </div>
          )}

          <div>
            <button
              type="submit"
              className="btn btn-primary w-full"
              disabled={loading}
            >
              {loading ? (
                <span className="loading loading-spinner loading-md"></span>
              ) : (
                t("login_button")
              )}
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm ">
          {t("login_dont_have_account")}
          <button
            onClick={handleNavigate}
            className="font-semibold leading-6 text-accent ml-1"
          >
            {t("login_signup_text")}
          </button>
        </p>
      </div>
    </div>
  )
}
