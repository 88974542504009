import { FiTrendingUp } from "react-icons/fi"
import { MdOutlinePriceCheck } from "react-icons/md"
import { FaRegNewspaper } from "react-icons/fa"
import { TbWriting } from "react-icons/tb"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

const Services = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const currentLanguage = useSelector((state) => state.language.lang)

  const handleNavigate = () => {
    let destinationURL

    if (currentLanguage === "en") {
      destinationURL = `/signup`
    } else {
      destinationURL = `/${currentLanguage}/signup`
    }
    navigate(destinationURL)
  }

  const features = [
    {
      description: t("feature_description_price"),
      icon: MdOutlinePriceCheck,
    },
    {
      description: t("feature_description_newspapers"),
      icon: FaRegNewspaper,
    },
    {
      description: t("feature_description_metrics"),
      icon: TbWriting,
    },
    {
      description: t("feature_description_writing"),
      icon: FiTrendingUp,
    },
  ]

  return (
    <div className="bg-white">
      <div className="px-4 sm:px-6 lg:px-8 mx-auto max-w-7xl">
        <div className="text-center py-6 sm:py-8 lg:py-12">
          <p className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {t("feature_component_title")}
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {t("feature_component_description")}
          </p>
        </div>
        <div className="mt-8 sm:mt-10 lg:mt-12 pb-6 sm:pb-8 lg:pb-12">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 lg:gap-x-16">
            {features.map((feature, index) => (
              <div
                key={index}
                className="flex items-center text-center md:text-left"
              >
                <dt>
                  <div className="flex justify-center md:justify-start">
                    <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-primary">
                      <feature.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </dt>
                <dd className="mt-2 ml-4">
                  <p className="text-base leading-7 text-gray-600">
                    {feature.description}
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
      <button
        className="btn btn-lg btn-outline bg-indigo-600 hover:bg-indigo-500 mb-6 w-11/12 text-white shadow-sm py-2 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={handleNavigate}
      >
        {t("feature_component_button")}
      </button>
    </div>
  )
}

export default Services
