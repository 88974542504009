import React from "react"
import { FaCheckCircle } from "react-icons/fa" 

const WelcomeAffiliate = ({ affiliateUrl }) => {
  return (
    <div className="flex flex-col items-center justify-center p-6 bg-white rounded-lg shadow-lg max-w-2xl mx-auto my-8">
      <FaCheckCircle className="text-green-500 mb-4" size="3em" />
      <h1 className="text-xl md:text-3xl font-bold text-gray-800 mb-3">
        Welcome to Our Affiliate Program!
      </h1>
      <p className="text-md md:text-lg text-gray-600 text-center mb-4">
        We are thrilled to have you onboard. You can now start sharing and
        earning with ease. Simply share your unique affiliate link and start
        earning rewards for every successful referral.
      </p>
      <div className="bg-gray-100 rounded-md p-4 w-full mx-10">
        <p className="text-md md:text-md font-bold text-black">
          {affiliateUrl.toLowerCase()}
        </p>
      </div>
    </div>
  )
}

export default WelcomeAffiliate
