import { postSiteInterest } from "../../services/siteService"
import { useState } from "react"

const InterestButton = ({ siteId, siteName, email }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const onClick = () => {
    setIsButtonDisabled(true)
    const siteInfo = {
      siteId: siteId,
      siteName: siteName,
      userEmail: email,
    }

    postSiteInterest(siteInfo)
  }

  return (
    <div>
      <button className="btn" onClick={onClick} disabled={isButtonDisabled}>
        <div className="flex items-center justify-center gap-2">
          I'm Interested
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          </svg>
        </div>
      </button>
    </div>
  )
}

export default InterestButton
