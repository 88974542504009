import React from "react"
import BuyForm from "./BuyForm"

function BuyPage() {
  return (
    <div>
      <BuyForm />
    </div>
  )
}

export default BuyPage
