import React, { useState, useEffect } from "react"
import SitesTable from "./SitesTable"
import SitesHeader from "./SitesHeader"
import SitesHeaderMobile from "./SitesHeaderMobile"
import { getPublicSites } from "../../services/siteService"
import Cookies from "js-cookie"
import { useNavigate } from "react-router-dom"

function SitesPage() {
  const [sites, setSites] = useState([])
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [languages, setLanguages] = useState([])
  const [error, setError] = useState("")
  const [selectedLanguage, setSelectedLanguage] = useState("Best Sellers")
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const [sitesPerPage, setSitesPerPage] = useState(isMobile ? 10 : 25)

  useEffect(() => {
    const token = Cookies.get("token")
    if (!token) {
      navigate("/login")
    }
  }, [navigate])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
      setSitesPerPage(window.innerWidth < 768 ? 8 : 25)
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  // Obtener todos los sitios
  useEffect(() => {
    const fetchSites = async () => {
      setIsLoading(true)
      setError("")
      try {
        const publicSites = await getPublicSites()
        setSites(publicSites)

        const uniqueLanguages = [
          ...new Set(publicSites.map((site) => site.Language.language_name)),
        ]
        setLanguages(uniqueLanguages)
      } catch (error) {
        console.error("Error fetching sites:", error)
        if (error.response && error.response.status === 401) {
          setError("Token is invalid or expired. Please log in again.")
        } else {
          setError("An error occurred while loading the sites.")
        }
      } finally {
        setIsLoading(false)
      }
    }

    fetchSites()
  }, [])

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    )
  }

  if (error) {
    return (
      <div className="p-5">
        <div role="alert" className="alert alert-error">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>{error}</span>
        </div>
      </div>
    )
  }

  return (
    <div>
      {isMobile ? (
        <SitesHeaderMobile
          languages={languages}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />
      ) : (
        <SitesHeader
          languages={languages}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />
      )}
      <SitesTable sites={sites} selectedLanguage={selectedLanguage} sitesPerPageAmount={sitesPerPage}/>
    </div>
  )
}

export default SitesPage
