import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

const JoinUsBar = () => {
  const navigate = useNavigate()
  const currentLanguage = useSelector((state) => state.language.lang)

  const handleNavigate = () => {
    let destinationURL

    if (currentLanguage === "en") {
      destinationURL = `/signup`
    } else {
      destinationURL = `/${currentLanguage}/signup`
    }
    navigate(destinationURL)
  }

  return (
    <div className="relative isolate flex flex-col sm:flex-row items-center gap-x-6 gap-y-2 sm:gap-y-0 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <div
        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
          }}
        />
      </div>
      <p className="text-sm leading-6 text-gray-900 text-center sm:text-left">
        <strong className="font-semibold">Welcome Promotion</strong>
        <svg
          viewBox="0 0 2 2"
          className="mx-2 inline h-0.5 w-0.5 fill-current"
          aria-hidden="true"
        >
          <circle cx={1} cy={1} r={1} />
        </svg>
        Sign Up and Save $5 on Your First Order!
      </p>
      <button
        onClick={handleNavigate}
        className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 cursor-pointer"
      >
        Register now <span aria-hidden="true">&rarr;</span>
      </button>
      <div className="flex flex-1 justify-end"></div>
    </div>
  )
}

export default JoinUsBar
