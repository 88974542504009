import React, { useRef } from "react"
import Hero from "./Hero"
import CardContainer from "../others/CardContainer"
import PackContainer from "./PacksContainer"
import Stats from "./Stats"
import Services from "./Services"
import YouTubePlayer from "./Video"
import Contact from "./Contact"
import JoinUsBar from "./JoinUsBar"
import Affiliate from "./Affiliate"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import Cookies from "js-cookie"

function Homepage() {
  const { t } = useTranslation()
  const offersRef = useRef(null)
  const packs = useSelector((state) => state.packs.list)
  const homepagePacks = packs.filter((pack) => pack.homepage)
  const userInfoEncoded = Cookies.get("userInfo")

  const shouldShowJoinUsBar = !userInfoEncoded

  const handleScrollToContainer = () => {
    offersRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  return (
    <div>
      {shouldShowJoinUsBar && <JoinUsBar />}
      <Hero onScrollToContainer={handleScrollToContainer} />
      <Stats />
      <div ref={offersRef}>
        <PackContainer packs={homepagePacks} text={t("current_sales_text")} />
      </div>
      <Services />
      {/*       <YouTubePlayer /> */}
      <Affiliate />
      <div>
        <CardContainer />
      </div>
      <div>
        <Contact />
      </div>
    </div>
  )
}

export default Homepage
