import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL
console.log(API_URL)

const getPacks = async () => {
  try {
    const response = await axios.get(`${API_URL}/packs`)
    return response.data
  } catch (error) {
    throw error
  }
}

export { getPacks }
