import { configureStore } from "@reduxjs/toolkit"
import languageReducer from "../reducers/languageReducer"
import packReducer from "../reducers/packReducer" 

export const store = configureStore({
  reducer: {
    language: languageReducer,
    packs: packReducer, 
  }
})
