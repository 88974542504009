import React from "react"
import Card from "../homepage/ReviewCard"

const reviews = [
  {
    avatar: "ZV",
    username: "Zehavie",
    country: "GB",
    review:
      "Everything was super great, work quality is beyond expectations. Thank you!",
  },
  {
    avatar: "ZH",
    username: "Zahidmeer",
    country: "PK",
    review:
      "Go above and beyond the call of duty, one of my favourite companies. Very cooperative and highly recommended.",
  },
  {
    avatar: "CM",
    username: "columnamusical",
    country: "MX",
    review:
      "This is the second time we have worked together with SEOfy. The work was done fast and with good quality. I'll probably team up with them again for future projects.",
  },
  {
    avatar: "R2",
    username: "roley2021",
    country: "CH",
    review:
      "Excellent. Easy communication with SEOfy. Always a pleasure to work with them.",
  },
  {
    avatar: "CM",
    username: "cesarmarin632",
    country: "US",
    date: "2 months ago",
    review:
      "Great experience with SEOfy. Looking forward to our next project together.",
  },
  {
    avatar: "JG",
    username: "jgarridoweb",
    country: "CL",
    date: "3 months ago",
    review:
      "Segundo proyecto con SEOfy con muy buenos resultados, excelente comunicación. Seguro seguiremos trabajando en conjunto. Muchas gracias.",
  },
  {
    avatar: "TR",
    username: "tasyarazman",
    country: "MY",
    date: "5 months ago",
    review:
      "Fantastic experience with SEOfy! The communication was top-notch, they understood my requirements perfectly. Impressed with the fast delivery and outstanding quality of the articles. Efficient and reliable. Highly recommended! ⭐⭐⭐⭐⭐",
  },
  {
    avatar: "DJ",
    username: "djs12345",
    country: "GB",
    date: "3 months ago",
    review:
      "Very good job as always with SEOfy. Professional and good writing.",
  },
  {
    avatar: "IT",
    username: "itsdoleey",
    country: "CA",
    date: "4 months ago",
    review:
      "Amazing service from SEOfy. They were open to making the necessary changes to meet our needs.",
  },
  {
    avatar: "FA",
    username: "farahanarazali",
    country: "MY",
    date: "4 months ago",
    review:
      "Always a pleasure to work with SEOfy! Good communication and well-written articles. Thank you.",
  },
]

const getRandomReviews = (reviews, count) => {
  const shuffled = [...reviews].sort(() => 0.5 - Math.random())
  return shuffled.slice(0, count)
}

const CardContainer = () => {
  const randomReviews = getRandomReviews(reviews, 3)
  return (
    <div className="flex flex-col md:flex-row gap-4 px-4 py-10 items-center justify-center bg-white mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {randomReviews.map((review, index) => (
          <Card
            key={index}
            avatar={review.avatar}
            username={review.username}
            review={review.review}
            country={review.country}
          />
        ))}
      </div>
    </div>
  )
}

export default CardContainer
