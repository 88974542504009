import React from 'react';

const Card = ({avatar, username, review, country}) => {

  // Structured data
const reviewData = {
  "@context": "http://schema.org",
  "@type": "Review",
  reviewBody: review,
  author: {
    "@type": "Person",
    name: username,
  },
  reviewRating: {
    "@type": "Rating",
    ratingValue: 5, 
    bestRating: "5", 
  },
}


  return (
    <div className="card max-w-full bg-white border border-gray-200 shadow-lg mx-auto my-2">
      <div className="card-body items-center text-center">
        <div className="flex justify-between items-center w-full mb-4">
          <div className="flex items-center">
            <div className="avatar placeholder mr-2">
              <div className="bg-neutral text-neutral-content rounded-full w-8">
                <span className="text-xs">{avatar}</span>
              </div>
            </div>
            <h5 className="text-lg font-semibold">{username}</h5>
          </div>

          <div className="rating mx-3">
            <input
              type="radio"
              name="rating-4"
              className="mask mask-star-2 bg-accent"
              disabled
            />
            <input
              type="radio"
              name="rating-4"
              className="mask mask-star-2 bg-accent"
              disabled
            />
            <input
              type="radio"
              name="rating-4"
              className="mask mask-star-2 bg-accent"
              disabled
            />
            <input
              type="radio"
              name="rating-4"
              className="mask mask-star-2 bg-accent"
              disabled
            />
            <input
              type="radio"
              name="rating-4"
              className="mask mask-star-2 bg-accent"
              disabled
            />
          </div>
        </div>
        <p className="text-gray-600 flex-1">{review}</p>
        <script type="application/ld+json">{JSON.stringify(reviewData)}</script>
      </div>
    </div>
  )
};

export default Card;
