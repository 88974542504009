import { useTranslation } from "react-i18next"

const Hero = ({ onScrollToContainer }) => {
  const { t } = useTranslation()

  return (
    <div
      className="hero min-h-screen"
      style={{
        backgroundImage: "url('/FONDO.jpeg')",
      }}
    >
      <div className="hero-overlay bg-opacity-60"></div>
      <div className="hero-content text-center text-neutral-content">
        <div className="max-w-md md:max-w-2xl">
          <h1 className="mb-5 text-5xl font-bold">{t("hero_title")}</h1>
          <p className="mb-5">{t("hero_text")}</p>
          <button
            className="btn btn-accent btn-lg w-11/12"
            onClick={onScrollToContainer}
          >
            {t("sales_button")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Hero
