import React, { useState, useEffect } from "react"
import Cookies from "js-cookie"
import { useNavigate } from "react-router-dom"
import { getOrdersByClientId } from "../../services/ordersService"
import OrdersTable from "./OrdersTable"
import { useTranslation } from "react-i18next"

const MyOrders = () => {
  const [orders, setOrders] = useState([])
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const navigate = useNavigate()

  useEffect(() => {
    const token = Cookies.get("token")
    if (!token) {
      navigate("/login")
    }
  }, [navigate])

  useEffect(() => {
    const fetchOrders = async () => {
      const userInfoEncoded = Cookies.get("userInfo")
      if (userInfoEncoded) {
        const userInfo = JSON.parse(decodeURIComponent(userInfoEncoded))
        try {
          setLoading(true)
          const data = await getOrdersByClientId(userInfo.client_id)
          setOrders(data)
        } catch (err) {
          setError("Failed to fetch orders")
        }
        setLoading(false)
      }
    }
    fetchOrders()
  }, [])

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div></div>
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    )
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  return (
    <div className="px-8">
      <div className="flex justify-start flex-col items-start pb-4">
        <h1 className="text-4xl font-extrabold dark:text-white">
          {t("orders_route")}
        </h1>
      </div>

      <div>
        <OrdersTable orders={orders} />
      </div>
    </div>
  )
}

export default MyOrders
