import axios from "axios"
import Cookies from "js-cookie"

const API_URL = process.env.REACT_APP_API_URL

const login = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}/login`, credentials)

    if (response.data.token) {
      const expires = 6 / 24
      Cookies.set("token", response.data.token, { expires: expires })
      Cookies.set("userInfo", JSON.stringify(response.data.client), {
        expires: expires,
      })
    }

    return response.data
  } catch (error) {
    throw error
  }
}

const requestPasswordReset = async (email) => {
  try {
    const response = await axios.post(`${API_URL}/password-reset`, { email })
    return response.data
  } catch (error) {
    console.error("Error requesting password reset:", error)
    throw error 
  }
}

const resetPassword = async ({ token, newPassword }) => {
  try {
    const response = await axios.post(`${API_URL}/change-password`, {
      token,
      newPassword,
    })
    return response.data
  } catch (error) {
    console.error("Error resetting password:", error)
    throw error
  }
}

export { login, requestPasswordReset, resetPassword }