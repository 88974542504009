import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import "./App.css"
import Footer from "./components/others/Footer"
import Header from "./components/others/Header"
import RoutesConfig from "./utils/routes"
import i18n from "i18next"
import { setLanguage } from "./reducers/languageReducer"
import { setPacks } from "./reducers/packReducer"
import { getPacks } from "./services/packService"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import ReactGA from "react-ga4"

function App() {
  const dispatch = useDispatch()

  ReactGA.initialize("G-NM39W5WEZR")


  useEffect(() => {
    
    dispatch(setLanguage(i18n.language))

    const loadPacks = async () => {
      try {
        const packs = await getPacks()
        dispatch(setPacks(packs))
      } catch (error) {
        console.error("Error loading packs:", error)
      }
    }

    loadPacks()
  }, [dispatch])

  return (
    <div className="App flex flex-col min-h-screen" data-theme="dark">
      <Router>
        <Header />
        <div className="flex-grow">
          <RoutesConfig />
        </div>
        <Footer />
      </Router>
    </div>
  )
}

export default App
