import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { sendFormData } from "../../services/contactService"
import { PiCheckCircleFill } from "react-icons/pi";
import i18n from "i18next"

const Form = () => {
  const [buttonState, setButtonState] = useState("normal")
  const { t } = useTranslation()
  

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    details: "",
    language: i18n.language,
  })

  const handleSubmit = async (event) => {
    event.preventDefault()
   try {
    const response = await sendFormData(formData);
    console.log('Server response::', response);
     setButtonState("success")
  } catch (error) {
    console.error("Error sending data:", error)
    setButtonState("error")
  }
  }

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }


  if (buttonState === "success") {
    return (
      <div className="flex flex-col items-center justify-center w-full my-32 md:my-56">
        <div className="text-center space-y-4 flex justify-center items-center gap-3">
          <PiCheckCircleFill size="5em" className="text-accent" />
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-2xl font-bold">{t("contact_success_message_title")}</h2>
            <p className="text-lg">{t("contact_success_message_body")}</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center justify-center w-full mb-8 md:mb-5">
      <h2 className="text-2xl font-bold mb-4">{t("contact_title")}</h2>
      <form onSubmit={handleSubmit} className="w-full max-w-sm">
        <div className="form-control mb-4">
          <label className="label">
            <span className="label-text">{t("form_label_name")}</span>
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="input input-bordered input-primary w-full"
            placeholder={t("input_placeholder_name")}
          />
        </div>

        <div className="form-control mb-4">
          <label className="label">
            <span className="label-text">{t("form_label_email")}</span>
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="input input-bordered input-primary w-full"
            placeholder={t("input_placeholder_email")}
            required
          />
        </div>

        <div className="form-control mb-4">
          <label className="label">
            <span className="label-text">{t("form_label_phone")}</span>
          </label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="input input-bordered input-primary w-full"
            placeholder={t("input_placeholder_phone")}
          />
        </div>

        <div className="form-control mb-4">
          <label className="label">
            <span className="label-text">{t("form_label_details")}</span>
          </label>
          <textarea
            name="details"
            value={formData.details}
            onChange={handleChange}
            className="textarea textarea-bordered textarea-primary"
            placeholder={t("textarea_placeholder_details")}
          ></textarea>
        </div>

        <button
          type="submit"
          className={`btn w-full ${
            buttonState === "normal" ? "btn-outline btn-accent" : ""
          }${
            buttonState === "error" ? "btn-error" : ""
          }`}
        >
          {buttonState === "normal" && t("button_submit")}
          {buttonState === "success" && t("button_submit_success")}
          {buttonState === "error" && t("button_submit_error")}
        </button>
      </form>
    </div>
  )
}

export default Form
