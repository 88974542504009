import AboutUs from "./AboutUs"
import Form from "./Form"

const Contact = () => {
  return (
    <div className="container mx-auto p-4 flex flex-wrap">
      <div className="w-full md:w-1/2">
        <Form />
      </div>
      <div className="w-full md:w-1/2">
        <AboutUs />
      </div>
    </div>
  )
}

export default Contact

