import React from "react"
import { Route, Routes } from "react-router-dom"
import Homepage from "../components/homepage/Homepage"
import NotFound from "../components/others/NotFound"
import ProductsPage from "../components/productsPage/ProductsPage"
import BuyPage from "../components/buyPage/BuyPage"
import Form from "../components/homepage/Form"
import SitesPage from "../components/sitesPage/SitesPage"
import LoginPage from "../components/login/LoginPage"
import SignUpPage from "../components/login/SignUpPage"
import AffiliateProgram from "../components/affiliate/AffiliateProgram"
import PasswordResetPage from "../components/login/PasswordResetPage"
import AffiliateSignUpPage from "../components/login/AffiliateSignUpPage"
import MyOrders from "../components/ordersPage/MyOrders"
import ResetForm from "../components/login/Reset"
import { Navigate } from "react-router-dom"

const RoutesConfig = () => {
  return (
    <Routes>
      {/* Rutas para Inglés */}
      <Route path="/" element={<Homepage />} />
      <Route path="/buy" element={<BuyPage />} />
      <Route path="/orders" element={<MyOrders />} />
      <Route path="/sites" element={<SitesPage />} />
      <Route path="/products" element={<ProductsPage />} />
      <Route path="/contact" element={<Form />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/affiliate-program" element={<AffiliateProgram />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/password-reset" element={<PasswordResetPage />} />
      <Route path="/signup/:affiliateName" element={<AffiliateSignUpPage />} />
      <Route path="/reset-password/:token" element={<ResetForm />} />

      {/* Rutas para Español */}
      <Route path="/es" element={<Homepage />} />
      <Route path="/es/buy" element={<BuyPage />} />
      <Route path="/es/orders" element={<MyOrders />} />
      <Route path="/es/sites" element={<SitesPage />} />
      <Route path="/es/password-reset" element={<PasswordResetPage />} />
      <Route path="/es/products" element={<ProductsPage />} />
      <Route path="/es/contact" element={<Form />} />
      <Route path="/es/affiliate-program" element={<AffiliateProgram />} />
      <Route path="/es/login" element={<LoginPage />} />
      <Route path="/es/signup" element={<SignUpPage />} />

      {/* Rutas para Portugues */}
      <Route path="/pt" element={<Homepage />} />
      <Route path="/pt/buy" element={<BuyPage />} />
      <Route path="/pt/orders" element={<MyOrders />} />
      <Route path="/pt/sites" element={<SitesPage />} />
      <Route path="/pt/products" element={<ProductsPage />} />
      <Route path="/pt/contact" element={<Form />} />
      <Route path="/pt/affiliate-program" element={<AffiliateProgram />} />
      <Route path="/pt/login" element={<LoginPage />} />
      <Route path="/pt/password-reset" element={<PasswordResetPage />} />
      <Route path="/pt/signup" element={<SignUpPage />} />

      {/* Ruta para Página No Encontrada */}
   {/*    <Route path="*" element={<Navigate to="/notfound" replace />} />
      <Route path="/notfound" element={<NotFound />} /> */}
    </Routes>
  )
}

export default RoutesConfig
