import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import i18n from "i18next"
import { sendBuyFormData } from "../../services/buyService"
import { PiCheckCircleFill } from "react-icons/pi"
import { IoIosArrowRoundBack } from "react-icons/io"
import { useNavigate } from "react-router-dom"
import Cookies from "js-cookie"

const BuyForm = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const productTitle = location.state?.productTitle
  const [buttonState, setButtonState] = useState("normal")
  const navigate = useNavigate()

  useEffect(() => {
    const userInfoEncoded = Cookies.get("userInfo")

    if (userInfoEncoded) {
      const userInfo = JSON.parse(decodeURIComponent(userInfoEncoded))

      setFormData((prevFormData) => ({
        ...prevFormData,
        email: userInfo.email,
      }))
    }
  }, [])

  const goToProducts = () => {
        let destinationURL

        if (i18n.language === "en") {
          destinationURL = `/${"products"}`
        } else {
          destinationURL = `/${i18n.language}/${"products"}`
        }
        navigate(destinationURL)

  }

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    details: "",
    articleIncluded: true,
    product: productTitle,
    language: i18n.language,
  })

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const response = await sendBuyFormData(formData)
      console.log("Server response:", response)
      setButtonState("success")
    } catch (error) {
      console.error("Error sending data:", error)
      setButtonState("error")
    }
  }

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    })
  }

  if (buttonState === "success") {
    return (
      <div className="flex flex-col items-center justify-center w-full my-32 md:my-56">
        <div className="text-center space-y-4 flex justify-center items-center gap-3">
          <PiCheckCircleFill size="5em" className="text-accent" />
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-2xl font-bold">
              {t("buy_success_message_title")}
            </h2>
            <p className="text-lg">{t("contact_success_message_body")}</p>
            <button className="btn btn-accent mt-3" onClick={goToProducts}>
              <IoIosArrowRoundBack size="1.5em" />
              {t("products_button")}
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <h2 className="text-2xl font-bold mb-2">{t("buy_form_title")}</h2>
      <p className="text-md mb-4">
        {t("buy_form_subtitle")} {productTitle}
      </p>

      <form onSubmit={handleSubmit} className="w-full max-w-sm">
        <div className="form-controlmb-2">
          <label className="label">
            <span className="label-text">{t("form_label_name")}</span>
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="input input-bordered input-primary w-full"
            placeholder={t("input_placeholder_name")}
          />
        </div>

        <div className="form-control mb-2">
          <label className="label">
            <span className="label-text">{t("form_label_email")}</span>
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="input input-bordered input-primary w-full"
            placeholder={t("input_placeholder_email")}
            required
          />
        </div>

        <div className="form-control mb-2">
          <label className="label">
            <span className="label-text">{t("form_label_phone")}</span>
          </label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="input input-bordered input-primary w-full"
            placeholder={t("input_placeholder_phone")}
          />
        </div>

        <div className="form-control mb-4">
          <label className="label">
            <span className="label-text"> {t("form_label_more_details")}</span>
          </label>
          <textarea
            name="details"
            value={formData.details}
            onChange={handleChange}
            className="textarea textarea-bordered textarea-primary"
            placeholder={t("form_placeholder_details")}
          ></textarea>
        </div>

        <div className="form-control mb-5">
          <label className="label cursor-pointer">
            <span className="label-text">
              {t("form_label_include_article")}
            </span>
            <input
              type="checkbox"
              name="articleIncluded"
              checked={formData.articleIncluded}
              onChange={handleChange}
              className="checkbox checkbox-primary"
            />
          </label>
        </div>

        <button
          type="submit"
          className={`btn w-full ${
            buttonState === "normal" ? "btn-outline btn-accent" : ""
          } ${buttonState === "error" ? "btn-error" : ""}`}
        >
          {buttonState === "normal" && t("button_submit")}
          {buttonState === "success" && t("button_submit_success")}
          {buttonState === "error" && t("button_submit_error")}
        </button>
      </form>
    </div>
  )
}

export default BuyForm
