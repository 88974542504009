import React, { useState, useEffect } from "react"
import { PiSortAscendingFill, PiSortDescendingFill } from "react-icons/pi"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import Cookies from "js-cookie"
import InterestButton from "./InterestButton"
import { CiSearch } from "react-icons/ci"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

const SitesTable = ({ sites, selectedLanguage, sitesPerPageAmount }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [sitesPerPage] = useState(sitesPerPageAmount)
  const [selectedCategory, setSelectedCategory] = useState("")
  const [selectedCountry, setSelectedCountry] = useState("")
  const [sortField, setSortField] = useState("site_id")
  const [sortDirection, setSortDirection] = useState("descending")
  const [categories, setCategories] = useState([])
  const [countries, setCountries] = useState([])
  const [languageFilteredSites, setLanguageFilteredSites] = useState([])
  const [filterString, setFilterString] = useState("")

  const [formData, setFormData] = useState({
    email: "",
    siteId: "",
    siteName: "",
  })

  useEffect(() => {
    const userInfoEncoded = Cookies.get("userInfo")

    if (userInfoEncoded) {
      const userInfo = JSON.parse(decodeURIComponent(userInfoEncoded))

      setFormData((prevFormData) => ({
        ...prevFormData,
        email: userInfo.email,
      }))
    }
  }, [])
useEffect(() => {

  let filteredSites =
    selectedLanguage === "All"
      ? sites
      : selectedLanguage === "Best Sellers"
      ? sites.filter((site) => site.public === true)
      : sites.filter((site) => site.Language.language_name === selectedLanguage)

  if (filterString) {
    filteredSites = filteredSites.filter((site) =>
      site.website_name.includes(filterString)
    )
  }

  setLanguageFilteredSites(filteredSites)
  setCategories([
    ...new Set(filteredSites.map((site) => site.Category.category_name)),
  ])
  setCountries([
    ...new Set(filteredSites.map((site) => site.Country.country_name)),
  ])


  setCurrentPage(1)
}, [selectedLanguage, sites, filterString])

useEffect(() => {
  setSelectedCategory("")
  setSelectedCountry("")
}, [selectedLanguage, sites])

  const handleSearchChange = (e) => {
    setFilterString(e.target.value)
  }

  const toggleSortDirection = () => {
    setSortDirection(sortDirection === "ascending" ? "descending" : "ascending")
  }

  const sortSites = (sites) => {
    return sites.sort((a, b) => {
      let fieldA = a[sortField]
      let fieldB = b[sortField]

      // Para campos numéricos
      if (typeof fieldA === "number" && typeof fieldB === "number") {
        return sortDirection === "ascending" ? fieldA - fieldB : fieldB - fieldA
      }

      // Para campos de texto
      if (typeof fieldA === "string" && typeof fieldB === "string") {
        return sortDirection === "ascending"
          ? fieldA.localeCompare(fieldB)
          : fieldB.localeCompare(fieldA)
      }

      return 0
    })
  }

  // Calcular el índice del último sitio en la página actual
  const indexOfLastSite = currentPage * sitesPerPage
  // Calcular el índice del primer sitio en la página actual
  const indexOfFirstSite = indexOfLastSite - sitesPerPage

  const filteredSites = languageFilteredSites.filter((site) => {
    const matchesCategory = selectedCategory
      ? site.Category && site.Category.category_name === selectedCategory
      : true
    const matchesCountry = selectedCountry
      ? site.Country && site.Country.country_name === selectedCountry
      : true

    return matchesCategory && matchesCountry
  })

  const sortedSites = sortSites(filteredSites)
  const currentSites = sortedSites.slice(indexOfFirstSite, indexOfLastSite)
  const totalPages = Math.ceil(sortedSites.length / sitesPerPage)

  // Manejar el cambio de página
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  return (
    <div className="overflow-x-auto p-5">
      <div className="flex items-center justify-start md:gap-5 md:p-5 md:flex-row flex-col p-4 gap-4">
        <div className="flex md:items-center gap-2 md:w-3/12 w-full">
          <div className="w-full flex items-center justify-center gap-2 flex-col md:flex-row">
            <label className="text-sm font-bold mb-2 ">
              Filter by Category
            </label>
            <select
              className="select select-sm select-bordered md:w-1/2 w-full"
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(e.target.value)
                setCurrentPage(1)
              }}
            >
              <option value="">All</option>
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex md:items-center gap-2 w-full md:w-3/12">
          <div className="w-full flex items-center justify-center gap-2 flex-col md:flex-row ">
            <label className="block text-sm font-bold mb-2">
              Filter by Country
            </label>
            <select
              className="select select-sm select-bordered md:w-1/2 w-full"
              value={selectedCountry}
              onChange={(e) => {
                setSelectedCountry(e.target.value)
                setCurrentPage(1)
              }}
            >
              <option value="">All</option>
              {countries.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex md:items-center gap-2 w-full md:w-3/12">
          <div className="w-full flex items-center justify-center gap-2 flex-col md:flex-row ">
            <label className="block text-sm font-bold mb-2">Sort by</label>
            <select
              className="select select-sm select-bordered md:w-1/2 w-full"
              value={sortField}
              onChange={(e) => setSortField(e.target.value)}
            >
              <option value="price">Price</option>
              <option value="dr">DR</option>
              <option value="traffic">Traffic</option>
              <option value="site_id">Most Recent</option>
            </select>
            <button onClick={toggleSortDirection}>
              {sortDirection === "ascending" ? (
                <PiSortAscendingFill className="text-3xl" />
              ) : (
                <PiSortDescendingFill className="text-3xl" />
              )}
            </button>
          </div>
        </div>

        <div className="flex md:items-center gap-2 w-full md:w-3/12">
          <div className="w-full flex items-center justify-center gap-2 flex-col md:flex-row ">
            <input
              type="text"
              placeholder="Search by name"
              className="input input-bordered input-sm w-full max-w-xs"
              value={filterString}
              onChange={handleSearchChange}
            />
          </div>
          <CiSearch className="text-3xl" />
        </div>
      </div>

      <Table className="table w-full table-compact">
        <Thead>
          <Tr>
            <Th className="hidden md:table-cell md:w-1/14">ID</Th>
            <Th className="w-6/14 md:w-3/14">Website</Th>
            <Th className="hidden md:table-cell md:w-2/14">Country</Th>
            <Th className="hidden md:table-cell md:w-2/14">Language</Th>
            <Th className="hidden md:table-cell md:w-2/14">Category</Th>
            <Th className="w-4/14 md:w-1/14 text-end">Price</Th>
            <Th className="w-2/14 md:w-1/14 text-end">DR</Th>
            <Th className="w-2/14 md:w-1/14 text-end">Traffic</Th>
            <Th className="w-2/14 md:w-1/14"> </Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentSites.map((site) => (
            <Tr key={site.site_id} className="hover">
              <Td className="w-2/14 md:w-1/14">{site.site_id}</Td>
              <Td className="w-4/14 md:w-3/14">{site.website_name}</Td>
              <Td className="hidden md:table-cell md:w-2/14">
                {site.Country.country_name}
              </Td>
              <Td className="hidden md:table-cell md:w-2/14">
                {site.Language.language_name}
              </Td>
              <Td className="hidden md:table-cell md:w-2/14">
                {site.Category.category_name}
              </Td>
              <Td className="w-3/14 md:w-1/14 text-end">
                {`${site.Currency.currency_icon}${site.price.toLocaleString(
                  "en-US"
                )}`}
              </Td>
              <Td className="w-2/14 md:w-1/14 text-end">{site.dr || "N/A"}</Td>
              <Td className="w-2/14 md:w-1/14 text-end">
                {site.traffic ? site.traffic.toLocaleString("en-US") : "N/A"}
              </Td>
              <Td className="w-1/14 flex items-center justify-center">
                <InterestButton
                  siteId={site.site_id}
                  siteName={site.website_name}
                  email={formData.email}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <div className="join mt-5">
        <button
          className={`join-item btn ${
            currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          «
        </button>
        <button className="join-item btn">
          Page {currentPage} of {totalPages}
        </button>
        <button
          className={`join-item btn ${
            currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() =>
            currentPage < totalPages && handlePageChange(currentPage + 1)
          }
          disabled={currentPage === totalPages}
        >
          »
        </button>
      </div>
    </div>
  )
}

export default SitesTable
